





















































import AddAdmin from "@/components/AddAdmin/AddAdmin.vue";
import { AddAdminType } from '@/includes/types/AddAdmin/Enums';
import ModuleBindings from "@/includes/logic/Modules/mixins/ModuleBindings";
import DailyReportModuleBuilder
  from "@/includes/logic/Modules/modules/modules/DailyReportModule/DailyReportModuleBuilder";
import GoBackButton from "../../../components/PageTitleButtons/GoBackButton.vue";
import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue';
import PageTitle from "piramis-base-components/src/components/PageTitle.vue";

import { Component, Mixins, VModel } from 'vue-property-decorator';

@Component({
  components: {
    AddAdmin,
    ConfigField,
    PageTitle,
    GoBackButton
  },
  data() {
    return {
      AddAdminType,
      ModuleManagerState
    }
  }
})
export default class DailyReportModule extends Mixins(ModuleBindings) {
  @VModel() module!: DailyReportModuleBuilder
}
